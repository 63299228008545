import { z } from "zod";

export namespace PaymentSummary {
  export const schema = z.object({
    bonus: z.number().optional(),
    commission: z.number().optional(),
    date: z.string(),
    id: z.string(),
    net: z.number().optional(),
    total: z.number().optional(),
    surcharge: z.number().optional(),
  });

  export type Type = z.infer<typeof schema>;
}

export type PaymentSummary = PaymentSummary.Type;

export namespace PaymentSummary {
  export const surcharge = (paymentSummary: PaymentSummary) => {
    if (paymentSummary.surcharge) {
      return paymentSummary.surcharge;
    }
    // if (!paymentSummary.bonus) {
    //   return 0;
    // }
    if (!paymentSummary.commission) {
      return paymentSummary.bonus ?? 0;
    }
    return (paymentSummary.bonus ?? 0) + paymentSummary.commission;
  };
}
